import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AlignJustify } from "lucide-react";
import { navItems } from "../data";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation(); // Get the current route

  const handleNavClick = () => {
    setIsOpen(false); // Close menu on item click
  };

  return (
    <nav className="bg-[#90151c]">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto px-4 font-inter">
        {/* Logo Left */}
        <Link to="/" className="flex items-center space-x-3">
          <img src="./images/royal-dews-logo.png" alt="" />
        </Link>

        {/* Hamburger Menu */}
        <div className="flex md:order-2">
          <button
            onClick={() => setIsOpen(!isOpen)}
            data-collapse-toggle="navbar-cta"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-white rounded-lg md:hidden"
            aria-controls="navbar-cta"
            aria-expanded={isOpen}
          >
            <AlignJustify />
          </button>
        </div>

        {/* Center Menu Items */}
        <div
          className={`${
            isOpen ? "block" : "hidden"
          } items-center justify-between w-full md:flex md:w-auto md:order-1`}
          id="navbar-cta"
        >
          <ul className="flex flex-col w-full h-full md:gap-5 gap-2 rounded-lg bg-[#90151c] md:space-x-8 md:flex-row md:mt-0 md:border-0 md:bg-[#90151c]">
            {navItems.map((item) => {
              // Determine if the current item is active by comparing the href to the current path
              const isActive = location.pathname === item.href;
              return (
                <li
                  key={item.label}
                  className={`${
                    isActive ? "bg-white" : ""
                  } flex items-center justify-center w-full md:w-auto`}
                >
                  <Link
                    to={item.href}
                    onClick={handleNavClick} // Close menu on click
                    className={`block py-4 px-3 w-full text-center md:text-left ${
                      isActive
                        ? "bg-white text-[#90151c] font-bold"
                        : "text-white"
                    } border-b-2 border-transparent hover:border-white  transition-all duration-300`}
                  >
                    {item.label}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
