import React from "react";

const Companydesc = () => {
  return (
    <section className="flex flex-col md:flex-row justify-center items-center sm:gap-4 gap-6 py-12 px-4 max-w-[1920px] mx-auto">
      {/* Text Content Section */}
      <div className="bg-[#fff5f1] rounded-[40px] p-6 sm:p-8 mx-4 flex-1 flex flex-col justify-center max-w-[600px]">
        <p className="text-black text-[16px] sm:text-[18px] leading-7 sm:leading-8 font-normal font-inter">
          We are "Ck Group of Industries"; our product brand is "The Royal
          Dews". Our product is packaged drinking water with added minerals.
          Royal Dews stands as a beacon of purity and wellness in the realm of
          packaged drinking water with added minerals.
        </p>
        <p className="text-black text-[16px] sm:text-[18px] leading-7 sm:leading-8 font-normal font-inter mt-4">
          Our state-of-the-art production unit is dedicated to crafting a
          refreshingly royal experience for our consumers. Meticulously designed
          to meet the highest quality standards, our facility ensures that every
          drop of Royal Dews water is a testament to excellence.
        </p>
      </div>

      {/* Image Section */}
      <div className="flex-1 flex justify-center items-center">
        <img
          className="w-full h-auto max-w-[300px] sm:max-w-[400px] rounded-[40px] object-cover shadow-lg"
          src="./images/this.png"
          alt="Company Image"
        />
      </div>
    </section>
  );
};

export default Companydesc;
