export const navItems = [
  { label: "About us", href: "/about" },
  { label: "Products", href: "/products" },
  { label: "Careers", href: "/careers" },
];

export const certificates = [
  "./images/Logo1.png",
  "./images/Logo2.png",
  "./images/Logo3.png",
  "./images/Logo4.png",
  "./images/Logo5.png",
];

export const productData = [
  {
    images: [
      "/images/p3.png",
      "/images/2nd-500-L.png",
      "/images/white-bg-1.png",
    ],
    quantity: "500ML",
    title: "The Royal Dew's",
    description: `Stay refreshed on the go with The Royal Dews 500ml Mineral Water, 
      the perfect choice for convenient hydration. Every drop undergoes a meticulous 
      3-step filtration process, including sand filtration for crystal clarity, 
      UV treatment to eliminate harmful microorganisms, and enrichment with 
      essential minerals for a naturally fresh taste. Certified by FSSAI, 
      this bottle delivers the purest quality, making it the ideal companion 
      for those who value both convenience and uncompromised purity in Tripura.`,
  },
  {
    images: ["/images/p2.png", "/images/2nd-1l.png", "/images/white-bg-2.png"],
    quantity: "1L",
    title: "The Royal Dew's",
    description: `Quench your thirst throughout the day with The Royal Dews 1L Mineral Water,
       the perfect hydration partner for daily needs. Our water is processed through a rigorous
        filtration system, ensuring sand filtration removes impurities, UV treatment provides safety,
         and added minerals enhance its revitalizing taste. Proudly certified by FSSAI, this bottle is trusted for its unmatched quality, making it Tripura’'s top choice for wholesome and refreshing hydration.`,
  },
  {
    images: ["/images/p1.png", "/images/2nd.png", "/images/white-bg-3.png"],
    quantity: "20L",
    title: "The Royal Dew's",
    description: `Designed for families, offices, and events, The Royal Dews 20L Mineral Water ensures you always have access to safe,
       clean, and delicious hydration. Each drop is carefully purified through a 3-step process,
        including sand filtration for purity, UV treatment for safety, and mineral enrichment for 
        a premium taste. With FSSAI certification, this large-size bottle guarantees superior 
        quality and reliability, making it the ultimate choice for the best mineral water in Tripura.`,
  },
];
