import React from "react";
import SlideBar from "../components/Home/Slidebar";
import CompanySection from "../components/Home/CompanySection";
import Poster from "../components/Home/Poster";
import MapSection from "../components/Home/MapSection";
import Certifications from "../components/Home/Certifications";

const Home = () => {
  return (
    <>
      <SlideBar />
      <CompanySection />
      <Poster />
      <Certifications />
      <MapSection />
    </>
  );
};

export default Home;
