import Slider from "react-slick";
import { ArrowLeft, ArrowRight } from "lucide-react"; // Import lucide-react icons
import { useState, useEffect } from "react";

const SlideBar = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Detect screen size
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640); // Mobile width threshold
    };

    handleResize(); // Check on initial render
    window.addEventListener("resize", handleResize); // Listen for resize events
    return () => window.removeEventListener("resize", handleResize); // Cleanup
  }, []);

  // Custom previous arrow button
  const PrevArrow = ({ onClick }) => (
    <div
      className="absolute left-4 top-1/2 transform -translate-y-1/2 z-10 cursor-pointer bg-gray-700 rounded-full p-1 text-white text-2xl"
      onClick={onClick}
    >
      <ArrowLeft size={24} />
    </div>
  );

  // Custom next arrow button
  const NextArrow = ({ onClick }) => (
    <div
      className="absolute right-4 top-1/2 transform -translate-y-1/2 z-10 cursor-pointer bg-gray-700 rounded-full p-1 text-white text-2xl"
      onClick={onClick}
    >
      <ArrowRight size={24} />
    </div>
  );

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    prevArrow: <PrevArrow />, // Custom previous arrow
    nextArrow: <NextArrow />, // Custom next arrow
  };

  const images = ["/images/1.png", "/images/HUH.png"];
  const Mobimages = ["/images/1Mobile.png", "/images/2Mobile.png"];

  // Choose images array based on screen size
  const currentImages = isMobile ? Mobimages : images;

  return (
    <Slider {...settings}>
      {currentImages.map((image, index) => (
        <div key={index}>
          <img
            src={image}
            alt={`Slide ${index + 1}`}
            className="object-cover "
          />
        </div>
      ))}
    </Slider>
  );
};

export default SlideBar;
