import React, { useState } from "react";

const ProductCard = ({ images, quantity, title, description, price }) => {
  const [mainImage, setMainImage] = useState(images[0]); // Default image

  // Function to change the main product image
  const changeImage = (imageSrc) => {
    setMainImage(imageSrc);
  };

  return (
    <div className="max-w-lg w-full py-4 lg:max-w-full lg:flex shadow-xl rounded-lg overflow-hidden mx-auto my-6 h-auto">
      {/* Product Images Section */}
      <div className="flex flex-col items-center w-full lg:w-1/3 h-full">
        <div className="w-auto h-72 min-h-[300px] bg-gray-100 mb-4 flex justify-center items-center">
          <img
            src={mainImage}
            alt="Product"
            className="w-full h-full object-contain"
            id="product-img"
          />
        </div>
        <div className="w-full flex justify-center gap-2 mt-2 overflow-x-auto">
          {/* Smaller images for changing the main image */}
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Image ${index + 1}`}
              className="w-16 h-16 object-cover cursor-pointer shadow-lg rounded-md"
              onClick={() => changeImage(image)}
            />
          ))}
        </div>
      </div>

      {/* Product Info Section */}
      <div className="w-full lg:w-2/3 p-4 flex flex-col justify-between h-full space-y-4 font-inter text-black">
        {/* Quantity and Title Section */}
        <div className="flex flex-col space-y-2">
          <div className="text-[18px] font-medium">{quantity}</div>
          <div className="font-medium text-xl">{title}</div>
        </div>

        {/* Description Section */}
        <div className="flex flex-col space-y-2">
          <label htmlFor="description" className="text-[18px] font-bold">
            Description:
          </label>
          <p id="description" className="text-xl">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
