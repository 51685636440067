import React from "react";
import ProductCard from "../components/ProductCard";
import { productData } from "../data";

const Products = () => {
  return (
    <div className="px-4 gap-6 max-w-screen-xl mx-auto">
      <div className="flex flex-col justify-center items-center gap-6">
        {productData.map((product, index) => (
          <div
            key={index}
            className="w-full sm:w-[600px] md:w-[800px] lg:w-[1100px]"
          >
            <ProductCard
              images={product.images}
              quantity={product.quantity}
              title={product.title}
              description={product.description}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Products;
