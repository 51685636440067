import React, { useState } from "react";
import Marquee from "react-fast-marquee";
import { certificates } from "../../data";

const Certifications = () => {
  const [isHovered, setIsHovered] = useState(false); // State for hover detection

  return (
    <section className="w-full flex flex-col items-center py-8 px-4 font-inter max-w-screen-[1920px]">
      <h2 className="text-black text-4xl sm:text-5xl font-bold font-inter mb-6 text-center">
        Certifications
      </h2>

      {/* Conditional Rendering */}
      <div
        className="w-full my-4"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isHovered ? (
          // Static Centered Layout When Hovered
          <div className="flex justify-center gap-6">
            {certificates.map((src, index) => (
              <img
                key={index}
                src={src}
                alt={`Certification ${index + 1}`}
                className="w-[130px] sm:w-[180px] lg:w-[200px] h-auto object-cover rounded-lg"
              />
            ))}
          </div>
        ) : (
          // Marquee When Not Hovered
          <Marquee gradient={false} speed={30}>
            {certificates.map((src, index) => (
              <div
                key={index}
                className="mx-6 flex justify-center items-center"
              >
                <img
                  src={src}
                  alt={`Certification ${index + 1}`}
                  className="w-[130px] sm:w-[180px] lg:w-[200px] h-auto object-cover rounded-lg"
                />
              </div>
            ))}
          </Marquee>
        )}
      </div>

      {/* Centered 3-line quote */}
      <p className="max-w-screen-lg text-center text-black text-lg md:text-xl lg:text-2xl font-medium font-inter my-8 px-4 leading-snug line-clamp-3">
        “Our mineral water is certified for purity, safety, and quality, meeting
        national standards with BIS, FSSAI, groundwater, and added minerals
        certifications. Each bottle represents our commitment to responsible
        sourcing, stringent quality control, and premium hydration.”
      </p>

      {/* Centered image below text */}
      <div className="w-full flex justify-center">
        <div className="w-[150px] sm:w-[200px] lg:w-[250px] h-auto">
          <img
            src="./images/pngimg.com.png"
            alt="Additional Certification"
            className="w-auto h-auto object-contain"
          />
        </div>
      </div>
    </section>
  );
};

export default Certifications;
