import React from "react";
import { Routes, Route } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Careers from "./pages/Careers";
import Products from "./pages/Products";

function App() {
  return (
    <div className="max-w-screen-[1920px] mx-auto w-full overflow-x-hidden">
      {" "}
      {/* Wrapper to handle overflow */}
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/products" element={<Products />} />
      </Routes>
    </div>
  );
}

export default App;
