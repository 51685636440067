import React from "react";
import { Facebook, Instagram } from "lucide-react"; // keep these
import { FaWhatsapp } from "react-icons/fa"; // import WhatsApp icon

const MapSection = () => {
  return (
    <section className="w-full bg-gradient-to-r from-[#7D0A0A] to-[#AA1A1A] text-white py-12 px-6 flex flex-col md:flex-row justify-between items-stretch gap-8">
      {/* Map Section */}
      <div className="flex flex-1 items-center justify-center text-black font-bold text-2xl h-80 md:h-auto">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14629.719394581713!2d91.4651751!3d23.5530006!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375399a332efac2f%3A0x912a1709d8afda76!2sTHE%20ROYAL%20DEWS!5e0!3m2!1sen!2sin!4v1731846085234!5m2!1sen!2sin"
          className="w-[500px] h-[250px]"
          style={{ border: "0" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>

      {/* Text Section */}
      <div className="flex flex-1 flex-col justify-start space-y-8">
        {/* Upper Part: Contact Details */}
        <div>
          <h3 className="text-2xl font-bold mb-4">
            For Sales & Distribution Enquiry
          </h3>
          {/* Mailto link for email */}
          <p className="text-lg font-normal leading-7">
            <a
              href="mailto:info@ckgroupofindustries.com"
              className="no-underline"
            >
              info@ckgroupofindustries.com
            </a>
          </p>
          {/* Tel link for phone number */}
          <p className="text-lg font-normal leading-7">
            <a href="tel:+919436580880" className="no-underline">
              +91 94365 80880
            </a>
          </p>
        </div>

        {/* Lower Part */}
        <div className="flex justify-around items-center">
          {/* Follow Us */}
          <div className="flex flex-col justify-start w-1/2">
            <h3 className="text-2xl font-bold mb-4">Follow us</h3>
            <div className="flex space-x-4">
              <a
                href="https://www.facebook.com/THEROYALDEWS"
                target="_blank"
                aria-label="Facebook"
              >
                <Facebook size={32} className="text-white" />
              </a>
              <a
                href="https://www.instagram.com/the_royal_dews/"
                target="_blank"
                aria-label="Instagram"
              >
                <Instagram size={32} className="text-white" />
              </a>
              <a href="https://wa.me/+919436580880" aria-label="WhatsApp">
                {" "}
                {/* WhatsApp link */}
                <FaWhatsapp size={32} className="text-white" />
              </a>
            </div>
          </div>

          {/* Address */}
          <div className="border-l-2 border-white pl-6 w-1/2">
            <h3 className="text-2xl font-bold mb-4">Address</h3>
            <p className="text-lg font-normal leading-7">
              Dhajanagar Industrial Estate,
            </p>
            <p className="text-lg font-normal leading-7">
              Udaipur Gomati Tripura
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MapSection;
