import React, { useState, useEffect } from "react";

const Poster = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Detect screen size
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640); // Mobile width threshold
    };

    handleResize(); // Check on initial render
    window.addEventListener("resize", handleResize); // Listen for resize events
    return () => window.removeEventListener("resize", handleResize); // Cleanup
  }, []);

  // Desktop and mobile image sources
  const desktopPoster = "./images/our-compnay.png";
  const mobilePoster = "./images/3Mobile.png";

  // Conditional poster based on screen size
  const posterSrc = isMobile ? mobilePoster : desktopPoster;

  return (
    <>
      <div className="w-screen">
        <img
          src={posterSrc}
          alt="Company Banner"
          className="w-full h-full object-cover"
        />
      </div>
    </>
  );
};

export default Poster;
