import React from "react";

const CompanySection = () => {
  return (
    <section className="max-w-screen-[1920px] flex flex-col md:flex-row items-center justify-center p-8 md:p-16 space-y-8 md:space-y-0">
      <div className="text-center md:text-left md:w-[45%]">
        <h2 className="font-bold text-black text-4xl md:text-5xl font-inter tracking-tight leading-tight">
          The Company
        </h2>
        <p className="mt-4 w-full md:w-[87%] text-lg md:text-xl font-inter text-black text-left leading-relaxed">
          We are "Ck Group of Industries"; our product brand is "The Taj Qua".
          Our product is packaged drinking water with added minerals. The Taj
          Qua stands as a beacon of purity and wellness in the realm of packaged
          drinking water. Our state-of-the-art production unit is dedicated to
          crafting a refreshingly royal experience for our consumers.
          Meticulously designed to meet the highest quality standards, our
          facility ensures that every drop of The Taj Qua is a testament to
          excellence.
        </p>
      </div>
      <div className="mt-6 md:mt-0 md:ml-8 w-full md:w-[50%]">
        <img
          className="w-full md:w-[586px] h-auto object-cover"
          alt="Company Product"
          src={"./images/asdasda.png"}
        />
      </div>
    </section>
  );
};

export default CompanySection;
