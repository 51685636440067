import React from "react";
import Poster from "../components/About/Poster";
import Companydesc from "../components/About/Companydesc";
import Commitment from "../components/About/Commitment";
import MapSection from "../components/Home/MapSection";

const About = () => {
  return (
    <div className="w-screen min-h-screen flex flex-col items-center justify-center mx-auto">
      {/* Poster Section */}
      <Poster />

      {/* Company Description Section */}
      <Companydesc className="mt-8" />

      {/* Commitment Section */}
      <Commitment className="mt-8" />

      {/* Footer Section */}
      <MapSection className="mt-8" />
    </div>
  );
};

export default About;
