import React from "react";

const CommitmentAndVision = () => {
  return (
    <>
      <div className="p-4 sm:p-14 max-w-screen-[1920px] mx-auto">
        {/* Commitment Section */}
        <section className="w-full px-6 py-8 sm:py-10 bg-[#98302c] rounded-[40px] sm:rounded-[63px] font-inter flex flex-col justify-center items-center gap-6 mb-12 sm:mb-16">
          <h1 className="text-white text-[24px] sm:text-[30px] md:text-[36px] font-bold text-center max-w-[600px] leading-tight">
            Our Commitment
          </h1>
          <p className="text-white text-[14px] sm:text-[16px] md:text-[18px] font-normal text-center max-w-[900px] sm:max-w-[1184px] leading-relaxed">
            The Royal Dews brand is synonymous with trust and sophistication.
            The name itself reflects our dedication to delivering water that is
            as pure and regal as its title suggests. With a focus on
            sustainability, our packaging mirrors our commitment to the
            environment, ensuring that every sip of Royal water contributes to a
            greener planet. In every bottle, Royal Dews encapsulates the essence
            of purity, health, and luxury. As a pioneer in the packaged drinking
            water industry, we will expand our business and capturing the whole
            market is an ambitious goal.
          </p>
        </section>

        {/* Vision Section */}
        <section className="w-full px-6 py-8 sm:py-10 bg-[#fff6f1] rounded-[40px] sm:rounded-[63px] flex flex-col justify-center items-center gap-6">
          <h1 className="text-[#98302c] text-[24px] sm:text-[30px] md:text-[36px] font-bold text-center max-w-[600px] leading-tight">
            Our Vision
          </h1>
          <p className="text-black text-[14px] sm:text-[16px] md:text-[18px] font-normal text-center max-w-[900px] sm:max-w-[1184px] leading-relaxed">
            Our vision is to redefine the standards of purity and wellness in
            the packaged drinking water industry. With The Royal Dews, we aspire
            to be a beacon of trust, health, and sustainability. Our commitment
            is to deliver not just water but a royal experience in every drop,
            blending innovation with responsibility to contribute to a
            healthier, greener planet. As pioneers in our field, we aim to
            expand our horizons and establish a leadership position in the
            market while staying true to our values of excellence and
            environmental stewardship.
          </p>
        </section>
      </div>
    </>
  );
};

export default CommitmentAndVision;
