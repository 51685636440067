import React, { useEffect, useState } from "react";

const Poster = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Detect screen size
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640); // Mobile width threshold
    };

    handleResize(); // Check on initial render
    window.addEventListener("resize", handleResize); // Listen for resize events
    return () => window.removeEventListener("resize", handleResize); // Cleanup
  }, []);

  // Desktop and mobile image sources
  const desktopPoster = "./images/2.png";
  const mobilePoster = "./images/our-products_Mobile.png";

  // Conditional poster based on screen size
  const posterSrc = isMobile ? mobilePoster : desktopPoster;
  return (
    <>
      <div className="">
        <img src={posterSrc} alt="" className="w-auto h-auto" />
      </div>
    </>
  );
};

export default Poster;
