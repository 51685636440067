import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import ProgressBar from "react-progressbar";
import axios from "axios";
import { toast } from "react-hot-toast";
import { Toaster } from "react-hot-toast";

const Careers = () => {
  const [isUploading, setIsUploading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [file, setFile] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: "",
    position: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    address: "",
    position: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let formErrors = { name: "", email: "", address: "", position: "" };
    if (!formData.name) formErrors.name = "Name is required";
    if (!formData.email) formErrors.email = "Email is required";
    else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zAZ0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)
    )
      formErrors.email = "Invalid email format";
    if (!formData.address) formErrors.address = "Address is required";
    if (!formData.position) formErrors.position = "Position is required";

    if (
      formErrors.name ||
      formErrors.email ||
      formErrors.address ||
      formErrors.position
    ) {
      setErrors(formErrors);
      return;
    }

    setIsUploading(true);

    const toastId = toast.loading("Sending email...", { autoClose: false });

    try {
      const data = {
        name: formData.name,
        email: formData.email,
        address: formData.address,
        position: formData.position,
        file: file,
        fileName: fileName,
      };

      await axios.post(`${process.env.REACT_APP_BASE_URL}/user/sendmail`, data);
      toast.success("Email sent successfully!", { id: toastId });

      setIsUploading(false);
      setFormData({ name: "", email: "", address: "", position: "" });
      setFile(null);
      setFileName("");
    } catch (error) {
      console.error("Error:", error);
      setIsUploading(false);
      toast.error("Error sending email!");
    }
  };

  const onDrop = (acceptedFiles) => {
    setIsUploading(true);
    const file = acceptedFiles[0];
    setFileName(file.name);

    const reader = new FileReader();
    reader.onload = () => {
      const base64File = reader.result.split(",")[1];
      setFile(base64File);
      setIsUploading(false);
    };
    reader.onerror = () => {
      alert("Error reading file");
      setIsUploading(false);
    };
    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".pdf, .doc, .docx, .txt",
  });

  return (
    <div className="w-full sm:h-auto h-screen flex items-center flex-col  bg-[#F2F5F7] pb-5">
      <img className="object-cover" src="./images/3.png" />
      <div className=" shadow-2xl  rounded-xl bg-white mt-5  p-6">
        <form className="max-w-sm" onSubmit={handleSubmit}>
          <div className="mb-5 flex flex-col">
            <label htmlFor="name" className="text-sm font-medium text-black">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="bg-transparent border border-black text-black text-sm rounded-full block w-full p-2.5 placeholder-black"
              placeholder="Enter Your Name"
            />
            {errors.name && (
              <p className="text-red-500 text-xs mt-1">{errors.name}</p>
            )}
          </div>

          <div className="mb-5 flex flex-col">
            <label htmlFor="email" className="text-sm font-medium text-black">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="bg-transparent border border-black text-black text-sm rounded-full block w-full p-2.5 placeholder-black"
              placeholder="Enter Your Email"
            />
            {errors.email && (
              <p className="text-red-500 text-xs mt-1">{errors.email}</p>
            )}
          </div>

          <div className="mb-5 flex flex-col">
            <label htmlFor="address" className="text-sm font-medium text-black">
              Address
            </label>
            <textarea
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              className="bg-transparent border border-black text-black text-sm rounded-lg block w-full p-2.5 placeholder-black"
              placeholder="Enter Your Address"
            ></textarea>
            {errors.address && (
              <p className="text-red-500 text-xs mt-1">{errors.address}</p>
            )}
          </div>

          <div className="mb-5 flex flex-col">
            <label
              htmlFor="position"
              className="text-sm font-medium text-black"
            >
              Position
            </label>
            <input
              type="text"
              id="position"
              name="position"
              value={formData.position}
              onChange={handleChange}
              className="bg-transparent border border-black text-black text-sm rounded-full block w-full p-2.5 placeholder-black"
              placeholder="Enter Position You Are Applying For"
            />
            {errors.position && (
              <p className="text-red-500 text-xs mt-1">{errors.position}</p>
            )}
          </div>

          {/* Upload File Section */}
          <div className="mb-5 flex items-center space-x-4 flex-col sm:flex-row">
            <div
              {...getRootProps()}
              className="w-[70px] h-[70px] cursor-pointer bg-white rounded-full flex justify-center items-center border-2 border-[#cbd0dc]"
            >
              {/* Upload Icon */}
              <img
                src="./images/cloud-add.png"
                alt="Upload Icon"
                className="w-[40px] h-[40px]"
              />
              {/* Hidden File Input */}
              <input {...getInputProps()} type="file" className="hidden" />
            </div>
            <label
              htmlFor="file"
              className="text-sm font-medium text-black text-center"
            >
              <h2 className="text-[#292d32] text-2xl font-medium font-inter">
                Upload Your CV/Resume
              </h2>
              <p className="text-[#a9abb4] text-sm font-medium font-inter">
                Select or drag and drop to upload your resume
              </p>
            </label>
          </div>

          {/* Show Loading or Progress Bar */}
          {isUploading ? (
            <div className="w-full mt-4">
              <div className="flex justify-center">
                <div className="animate-spin rounded-full border-4 border-t-4 border-blue-500 w-8 h-8"></div>
              </div>
              <p className="text-center mt-2 text-sm">Sending email...</p>
              <ProgressBar
                completed={uploadProgress}
                className="mt-2"
                bgcolor="#375ef9"
                height="10px"
                borderRadius="12px"
                isLabelVisible={true}
                label={`${uploadProgress}%`}
              />
              <p className="mt-2 text-center text-sm text-gray-600">
                {fileName}
              </p>
            </div>
          ) : (
            fileName &&
            !isUploading && (
              <div className="mt-4 text-center text-sm">
                <p className="text-green-600">Email Sent!</p>
                <p>{fileName}</p>
              </div>
            )
          )}

          {/* Submit Button shown only after upload */}
          {!isUploading && fileName && (
            <button
              type="submit"
              className="text-white mt-5 bg-[#d82042] hover:bg-[#b5182e] focus:ring-4 focus:outline-none focus:ring-[#b5182e] font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
            >
              Sand
            </button>
          )}
        </form>
      </div>

      {/* Toast Container */}
      <Toaster />
    </div>
  );
};
export default Careers;
